<template>
  <v-container class="wp-page news-list">
    <breadcrumb :items="breadcrumb" />
    <div class="recipe-header mb-2" v-if="page">
      <v-img
        :src="page.featured_image_url"
        heigth="250"
        min-height="250"
        class="d-flex justify-center align-center rounded"
      >
        <div
          class="pa-10 white rounded-circle d-flex align-center justify-center recipe-header-icon"
        >
          <v-img min-width="42" :src="`/img_layout/wordpress/${type}.svg`" />
        </div>
        <h1 v-html="title" class="text-center white--text"></h1>
        <div v-html="content" class="text-center white--text"></div>
      </v-img>
    </div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        :md="post.type === 'video' ? 6 : 4"
        :lg="post.type === 'video' ? 6 : 4"
        :xl="post.type === 'video' ? 4 : 3"
        v-for="post in newsList"
        :key="post.id"
      >
        <PostCard :post="post" />
      </v-col>
    </v-row>
    <v-pagination
      class="mt-12"
      color="secondary"
      v-if="pager && pager.totPages > 1"
      v-model="pageNum"
      :length="pager.totPages > 6 ? 6 : pager.totPages"
      :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>
  </v-container>
</template>
<style scoped lang="scss">
@import url("/p/wp-content/themes/abbondanza/styles-common.css?ver=wp");
.news-list {
  .recipe-header {
    .recipe-header-icon {
      display: block;
      margin: 0 auto;
      width: 95px;
      height: 95px;
      img {
        height: 50px;
        max-height: 50px;
      }
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";

// import { mapGetters } from "vuex";
import PostCard from "@/components/wordpress/PostCard.vue";
import page from "~/mixins/page";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "NewsList",
  mixins: [page, clickHandler],
  components: {
    PostCard,
    Breadcrumb
  },
  props: {
    cat: {
      type: String,
      default: null
    },
    pageNum: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: "post"
    }
  },
  data() {
    return {
      newsList: null,
      pager: {},
      parentCategories: null,
      noPostToShow: false
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    async fetchNews() {
      // this filters are not actually working...
      let params = [
        {
          filterName: "filter[post_type]",
          filterValue: this.type
        }
        // {
        //   filterName: "post_type",
        //   filterValue: this.type
        // },
        // {
        //   filterName: "type",
        //   filterValue: this.type
        // }
      ];
      // params.push({
      //   page: this.pageNum
      // });

      if (this.cat != null && this.cat.length > 0) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }
      const res = await CMService.getCustomPostByFilters(
        "posts",
        params,
        12,
        this.pageNum
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.newsList = res.data;
      } else {
        this.newsList = null;
      }
    },
    handlePageFilter(page) {
      let query = {
        page: page
      };
      if (this.cat) {
        query.cat = this.cat;
      }
      this.$router.push({
        name: this.$route.name,
        query: query
      });
      // setTimeout(function() {
      //   _this.$vuetify.goTo(0);
      // }, 300);
    }
  },
  async mounted() {
    this.page = this.$route.params.page;
    await this.fetchNews();
  },
  watch: {
    async cat() {
      this.fetchNews();
    },
    async pageNum() {
      this.fetchNews();
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "meta.seo_title"),
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.page,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
